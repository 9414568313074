<template>
  <cs-modal
    :show="show"
    class="ev-destructive-item-modal"
    layout="medium"
    @close="close"
  >
    <div slot="header">{{ header }}</div>
    <div slot="body" class="ev-destructive-item-modal__modal-body">
      <cs-alert v-if="error" variant="danger" title="Error!" :content="error">
      </cs-alert>
      <p class="cs-textstyle-paragraph-bold" v-if="title">
        {{ title }}
      </p>
      <p class="cs-textstyle-paragraph-small" v-if="message">
        {{ message }}. To confirm, type <strong>{{ keyword }}</strong
        >.
      </p>
      <cs-form v-if="keyword" class="ev-destructive-item-modal__vertical-form">
        <cs-input
          :label="inputLabel"
          v-model="keywordInput"
          required
          :placeholder="keyword"
        />
      </cs-form>
    </div>
    <cs-button
      slot="main"
      :disabled="!validForm || loading"
      :variant="buttonVariant"
      corners="rounded"
      @click="onDelete"
      >{{ deleteLabel }}</cs-button
    >
    <div v-if="loading" class="ev-destructive-item-modal__loading" slot="body">
      <div class="ev-destructive-item-modal__loading-background"></div>
      <cs-spinner />
    </div>
  </cs-modal>
</template>
<script>
export default {
  props: {
    keyword: {
      type: String,
      default: '',
    },
    mutation: {
      type: Object,
      default: null,
    },
    header: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    message: {
      type: String,
      default: '',
    },
    inputLabel: {
      type: String,
      default: '',
    },
    deleteLabel: {
      type: String,
      default: 'Delete',
    },
    buttonVariant: {
      type: String,
      default: 'danger',
    },
    show: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: false,
      keywordInput: '',
      error: null,
    };
  },
  computed: {
    validForm() {
      return this.keywordInput === this.keyword;
    },
  },
  methods: {
    close() {
      if (this.loading) return;
      this.$emit('close');
      this.keywordInput = '';
      this.error = null;
    },
    async onDelete() {
      if (!this.mutation) return;

      this.loading = true;
      try {
        const response = await this.$apollo.mutate(this.mutation);
        this.$emit('delete', response);
        this.loading = false; // Keep this in here so that the close event will be emitted
        this.close();
      } catch (err) {
        this.error = err.message;
      }
      this.loading = false;
    },
  },
};
</script>
<style scoped>
.ev-destructive-item-modal {
}

.ev-destructive-item-modal.cs-modal {
  ---cs-modal-max-height: 90%;
}
.ev-destructive-item-modal.cs-modal >>> .cs-modal__popup {
  border: none;
}
.ev-destructive-item-modal__modal-body {
  padding-top: 16px;
}
.ev-destructive-item-modal__vertical-form {
  display: flex;
  flex-direction: column;
}
.ev-destructive-item-modal__vertical-form >>> .cs-textstyle-label-text {
  font-weight: bold;
}
.ev-destructive-item-modal__vertical-form >>> .cs-select__label {
  display: block;
}

.ev-destructive-item-modal__loading {
  display: flex;
  align-items: center;
  justify-content: center;
}
.ev-destructive-item-modal__loading,
.ev-destructive-item-modal__loading-background {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.ev-destructive-item-modal__loading-background {
  background-color: var(--cs-gray-06);
  opacity: 0.2;
}
.cs-alert {
  margin-top: 10px;
}
.modal-body {
  padding-top: 16px;
}
</style>
