<template>
  <div>
    <cs-modal :show="show" class="ev-upload-video-modal" @close="onClose">
      <div slot="header">Upload Video</div>
      <div slot="body">
        <div class="video-upload-modal-body">
          <!-- Select Video -->
          <div v-if="showSelect">
            <app-file-input
              title="Drag video here, or:"
              button-label="Select from computer"
              accept="video/*, audio/*"
              :multiple="false"
              @selected="selectFile"
            />
          </div>
          <!-- <div v-if="showThumbnail" class="video-thumbnail mt-2">
            <div class="video-information-modal">
              <div class="cs-textstyle-detail-heading video-main-title">
                Selected Video
              </div>
            </div>
            <div class="video-thumbnail__option">
              <div>{{ draft.file.name }}</div>
            </div>
          </div> -->
        </div>

        <div v-if="uploading" class="mt-3 file-info">
          <div class="doc-icon"><i class="cs-icons-doc-filled"></i></div>
          <div class="ev-file-info__uploading">
            <div class="cs-textstyle-paragraph-bold mb-1">
              <div>{{ draft.file.name }} &#x2022; Uploading</div>
              <div>{{ ((uploadProgress / 100) * 100).toFixed() }}%</div>
            </div>
            <b-progress
              :max="100"
              variant="--cs-primary"
              class="prog-bar"
              :value="uploadProgress"
            >
            </b-progress>
          </div>
        </div>
        <!-- Footer Button -->
      </div>
    </cs-modal>
  </div>
</template>

<script>
import AppFileInput from '@/components/general/FileInput.vue';
import CreateVideoUpload from '@/gql/videos/CreateVideoUpload.gql';
import SetVideoStatus from '@/gql/videos/SetVideoStatus.gql';
import axios from 'axios';

export default {
  components: { AppFileInput },
  props: {
    show: {
      type: Boolean,
      required: true,
    },
    libraryId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      errorTitle: null,
      errorDescription: null,
      url: null,
      draft: {},
      uploading: false,
      showSelect: true,
      showInfo: false,
      showThumbnail: false,
      thumbnails: null,
      thumbnail: null,
      disableButton: true,
      uploadProgress: 0,
    };
  },
  methods: {
    onClose() {
      if (this.loading) return;
      this.$emit('close');
    },
    async selectFile(file) {
      // this.disable = false;
      this.showSelect = false;
      this.showThumbnail = true;
      this.draft.file = file;
      this.disableButton = false;
      this.uploading = true;
      this.disableButton = true;
      const resp = await this.$apollo.mutate({
        mutation: CreateVideoUpload,
        variables: {
          contentType: this.draft.file.type,
          libraryId: this.libraryId,
        },
      });
      const signedUrl = resp.data.create_video_upload.upload_url;
      const vidId = resp.data.create_video_upload.video_id;
      await this.createVideo(signedUrl, vidId);
      this.uploading = false;
    },
    async createVideo(signedUrl, vidId) {
      const videoUrl = URL.createObjectURL(this.draft.file);
      const video = document.createElement('video');
      video.src = videoUrl;
      this.draft.videoUrl = videoUrl;
      await this.$apollo.mutate({
        mutation: SetVideoStatus,
        variables: {
          status: 'UPLOADING',
          id: vidId,
        },
      });
      try {
        const response = await axios.put(signedUrl, this.draft.file, {
          headers: {
            'Content-Type': this.draft.file.type,
            Accept: this.draft.file.type,
          },
          onUploadProgress: (progressEvent) => {
            const totalBytes = this.draft.file.size;
            const uploadedBytes = progressEvent.loaded;
            this.uploadProgress = Math.round(
              (uploadedBytes / totalBytes) * 100
            );
          },
        });

        // console.log('Video uploaded successfully.', response);
        await this.$apollo.mutate({
          mutation: SetVideoStatus,
          variables: {
            status: 'UPLOAD_COMPLETE',
            id: vidId,
          },
        });
        this.disableButton = false;
        this.uploading = false;
        this.$emit('success', vidId);
      } catch (error) {
        console.error('Error uploading video:', error);
        this.uploading = false;
      }
    },
  },
};
</script>

<style scoped>
.file-info {
  background: var(--cs-primary-lightest);
  border-radius: 5px;
  display: flex;
  align-items: center;
}
.doc-icon {
  font-size: 50px;
  color: var(--cs-primary-base);
}
.ev-file-info__uploading {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 5px;
}
.ev-file-info__uploading div:first-child {
  display: flex;
  justify-content: space-between;
}
.prog-bar {
  background-color: var(--cs-gray-00) !important;
}
</style>
