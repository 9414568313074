<template>
  <cs-card class="cursor-pointer" @click="goToDetails">
    <img v-if="thumbnail" slot="media" :src="thumbnail" />
    <div slot="header">
      <div class="d-flex justify-content-between align-items-center mb-2">
        <cs-tag :class="video.status">{{
          video.status.split('_').join(' ')
        }}</cs-tag>
        <div class="cs-textstyle-paragraph-small">
          {{ video.length > 0 ? `${toTimeString(video.length)}` : '' }}
        </div>
      </div>
      <h3>
        {{ video.title || video.id }}
      </h3>
    </div>
    <!-- <div slot="body">
      <p>
        {{ video.description }}
      </p>
    </div> -->
  </cs-card>
</template>
<script>
import { toTimeString } from '@/services/utils';

const validThumbnailStatues = ['IMPORT_COMPLETE', 'UPLOAD_COMPLETE'];

export default {
  props: {
    video: {
      type: Object,
      required: true,
    },
  },
  data() {
    return { toTimeString };
  },
  computed: {
    thumbnail() {
      const {
        library_id: libraryId,
        id,
        thumbnail_file_name: thumbnailFileName,
        status,
      } = this.video;
      return validThumbnailStatues.includes(status)
        ? `https://d1k5ygkuw0d0r6.cloudfront.net/${libraryId}/${id}/${thumbnailFileName}`
        : '';
    },
  },
  methods: {
    goToDetails() {
      this.$router.push({
        name: 'VideoDetail',
        params: { id: this.video.library_id, videoId: this.video.id },
      });
    },
  },
};
</script>

<style scoped>
.ev-video-item {
  background-color: var(--cs-gray-00);
  padding-top: var(--cs-card-padding-y);
  border-radius: 5px;
}
.ev-video-item__details {
  padding: var(--cs-card-padding-x) var(--cs-card-padding-y);
  padding-top: 0;
}
.icon-class {
  font-size: 24px;
}
.video-name {
  padding-top: 10px;
}
</style>
