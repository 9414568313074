import Vue from 'vue';
import VueRouter from 'vue-router';

import Libraries from '@/views/libraries/Libraries.vue';
import Library from '@/views/libraries/Library.vue';
import Hooks from '@/views/hooks/Hooks.vue';
import VideoDetail from '@/components/library/VideoDetail.vue'

import Users from '@/views/users/Users.vue';
import Keys from '@/views/keys/Keys.vue';
import Login from '@/views/login/Login.vue';

import $auth from '@/services/auth';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    redirect: '/libraries',
  },
  {
    path: '/libraries',
    name: 'Libraries',
    component: Libraries,
  },
  {
    path: '/libraries/:id',
    name: 'Library',
    component: Library,
    props: true,
  },
  {
    path: '/libraries/:id/video/:videoId',
    name: 'VideoDetail',
    component: VideoDetail,
    props: true,
  },
  {
    path: '/libraries/:id/hooks',
    name: 'Hooks',
    component: Hooks,
    props: true,
  },
  {
    path: '/users',
    name: 'Users',
    component: Users,
  },
  {
    path: '/keys',
    name: 'Keys',
    component: Keys,
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
  },
];

const router = new VueRouter({
  routes,
});

router.beforeEach(async (to, from, next) => {
  if ($auth.isLoggedIn() && to.fullPath.includes('/login')) {
    next('/');
  } else if (!$auth.isLoggedIn() && !to.fullPath.includes('/login')) {
    next('/login');
  } else {
    next();
  }
});

export default router;
