<template>
  <div>
    <cs-modal :show="show" class="ev-key-modal" @close="onClose">
      <div slot="header">Create Project Key</div>
      <div slot="body">
        <div v-if="token" class="mt-4">
          <cs-alert
            show-icon
            variant="warning"
            title="Please save this token"
            content="You will not be able to view it again"
          >
          </cs-alert>

          <div class="ev-key-token-container">
            <div class="copy-success">
              <cs-alert
                v-show="showCopiedSuccessMsg"
                variant="success"
                title="Copied!"
                can-close
              ></cs-alert>
            </div>
            <div class="cs-textstyle-list-tag token-heading">Token</div>
            <div class="token-body">
              <div class="cs-textstyle-paragraph-small-bold">
                {{ token }}
              </div>
              <i class="cs-icons-copy" @click="onCopy"></i>
            </div>
          </div>
        </div>
        <cs-form v-else class="ev-key-modal__vertical-form">
          <!-- Key Name -->
          <cs-input
            v-model="name"
            required
            label="Key Name"
            placeholder="eg.my-great-key"
          />
        </cs-form>
        <cs-alert
          v-if="errorTitle"
          :title="errorTitle"
          :content="errorDescription"
          variant="danger"
        />
      </div>
      <cs-button
        v-if="token"
        slot="main"
        corners="rounded"
        variant="primary"
        @click="onOk"
        fill="solid"
        >Ok</cs-button
      >
      <cs-button
        v-else
        slot="main"
        corners="rounded"
        variant="primary"
        @click="onCreate"
        fill="solid"
        :disabled="!validForm || connecting"
        >Create</cs-button
      >
      <div v-if="connecting" class="ev-key-modal__loading" slot="body">
        <div class="ev-key-modal__loading-background"></div>
        <cs-spinner />
      </div>
    </cs-modal>
  </div>
</template>

<script>
import CreateKey from '@/gql/keys/CreateKey.gql';
import GetKeySecret from '@/gql/keys/GetKeySecret.gql';

export default {
  components: {},
  props: {
    show: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      name: '',
      connecting: false,
      errorTitle: null,
      errorDescription: null,
      token: '',
      showCopiedSuccessMsg: false,
    };
  },
  computed: {
    validForm() {
      return !!this.name;
    },
  },
  methods: {
    async onCreate() {
      try {
        this.connecting = true;
        const {
          data: { key },
        } = await this.$apollo.mutate({
          mutation: CreateKey,
          variables: { name: this.name },
        });
        if (key && key.id) {
          const {
            data: { secret },
          } = await this.$apollo.query({
            query: GetKeySecret,
            variables: { id: key.id },
          });
          this.token = secret;
          this.errorTitle = null;
          this.errorDescription = null;
        }
      } catch (err) {
        this.errorTitle = err.message;
        this.errorDescription =
          err.graphQLErrors[0].extensions.exception.description;
      }
      this.connecting = false;
    },
    onOk() {
      this.token = '';
      this.name = '';
      this.$emit('success');
    },
    onClose() {
      if (this.connecting) return;
      this.token = '';
      this.name = '';
      this.$emit('close');
    },
    onCopy() {
      navigator.clipboard.writeText(this.token).then(() => {
        this.showCopiedSuccessMsg = true;
        setTimeout(() => {
          this.showCopiedSuccessMsg = false;
        }, 2500);
      });
    },
  },
};
</script>

<style scoped>
.ev-key-modal.cs-modal {
  ---cs-modal-max-height: 90%;
}
.ev-key-modal.cs-modal >>> .cs-modal__popup {
  border: none;
}
.ev-key-modal__vertical-form {
  padding-top: 16px;
  display: flex;
  flex-direction: column;
}
.ev-key-modal__vertical-form >>> .cs-textstyle-label-text {
  font-weight: bold;
}
.ev-key-modal__vertical-form >>> .cs-select__label {
  display: block;
}

.ev-key-modal__loading {
  display: flex;
  align-items: center;
  justify-content: center;
}
.ev-key-modal__loading,
.ev-key-modal__loading-background {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.ev-key-modal__loading-background {
  background-color: var(--cs-gray-06);
  opacity: 0.2;
}
.ev-key-token-container {
  position: relative;
}
.cs-icons-copy {
  font-size: 30px;
  color: var(--cs-primary-base);
  cursor: pointer;
}
.ev-key-token-container .copy-success {
  position: absolute;
}

.ev-key-token-container .copy-success .cs-alert {
  width: 140px;
}
.token-heading {
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.6px;
  text-transform: uppercase;
  color: var(--cs-gray-06);
}
.token-body {
  display: flex;
  align-items: center;
  gap: 10px;
}
</style>
