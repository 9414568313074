import jwt from 'jsonwebtoken';
import GetUser from '@/gql/users/GetUser.gql';
import $apollo from '@/services/apollo';
import { publicKey, signOptions } from './utils';
// import $push from './push';

const isLoggedIn = () => {
  const token = localStorage.access_token;
  if (!token) return false;
  try {
    jwt.verify(token, publicKey, signOptions);
    // $push.register();
    return true;
  } catch (err) {
    localStorage.clear();
    return false;
  }
};

const getUserId = () => {
  if (localStorage.access_token) {
    const token = localStorage.access_token;
    const { id } = jwt.decode(token, publicKey, signOptions);
    return id;
  }
  return null;
};

const getUser = async () => {
  const {
    data: { user },
  } = await $apollo.query({
    query: GetUser,
    variables: {
      getUserId: getUserId(),
    },
  });
  return user;
};

export default {
  isLoggedIn,
  getUserId,
  getUser,
};
