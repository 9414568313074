<template>
  <div class="ev-accounts-list">
    <div class="ev-dashboard__header">
      <div>
        <div class="cs-textstyle-page-title">Libraries</div>
      </div>
      <div class="ev-accounts-list__accounts">
        <cs-button corners="rounded" @click="openCreateLibraryModal">
          Create Library
        </cs-button>
      </div>
    </div>
    <app-gray-card class="libraries-list">
      <app-list-control-bar
        @refresh="refresh"
        v-model="val"
        placeholder="Filter Libraries"
      />
      <div>
        <b-table
          :items="libraries"
          :fields="fields"
          outlined
          :filter="filteredResult"
          table-variant="light"
          tbody-tr-class="ev-dashboard__table-row cursor-pointer"
          :busy="$apollo.queries.libraries.loading"
          show-empty
          @row-clicked="goToDetailPage"
        >
          <div slot="table-busy" class="ev-table-empty">
            <cs-spinner />
          </div>
          <div slot="empty" class="ev-table-empty">
            You don't have any libraries yet.
          </div>
          <div slot="emptyfiltered" class="ev-table-empty">
            No matching libraries.
          </div>
        </b-table>
      </div>
    </app-gray-card>

    <app-create-library-modal
      :show="showCreateLibraryModal"
      @close="closeCreateLibraryModal(false)"
      @success="closeCreateLibraryModal(true)"
    />
    <app-rename-library-modal
      v-if="selectedLibrary"
      :selected-library="selectedLibrary"
      :show="showRenameLibraryModal"
      @close="closeRenameLibraryModal(false)"
      @success="closeRenameLibraryModal(true)"
    />
  </div>
</template>
<script>
import ListLibraries from '@/gql/libraries/ListLibraries.gql';

import AppCreateLibraryModal from '@/components/library/CreateLibraryModal.vue';
import AppRenameLibraryModal from '@/components/library/RenameLibraryModal.vue';
import AppListControlBar from '@/components/general/ListControlBar.vue';
import AppGrayCard from '@/components/general/GrayCard.vue';

import { deleteLibrary } from '@/services/confirm-delete';

export default {
  components: {
    AppCreateLibraryModal,
    AppRenameLibraryModal,
    AppListControlBar,
    AppGrayCard,
  },
  apollo: {
    libraries: ListLibraries,
  },
  data() {
    return {
      val: '',
      fields: [
        {
          key: 'name',
          label: 'Name',
          thClass: 'ev-dashboard__table-head',
        },
        {
          key: 'total_video_minutes',
          label: 'Total Video Minutes',
          thClass: 'ev-dashboard__table-head',
        },
        {
          key: 'total_video_count',
          label: 'Total Video Count',
          thClass: 'ev-dashboard__table-head',
        },
      ],
      showCreateLibraryModal: false,
      showRenameLibraryModal: false,
      selectedLibrary: null,
    };
  },
  computed: {
    filteredResult() {
      if (this.val) {
        return this.val;
      }
      return '';
    },
  },
  beforeMount() {
    if (this.$apollo.queries.libraries) {
      this.refresh();
    }
  },
  methods: {
    openCreateLibraryModal() {
      this.showCreateLibraryModal = true;
    },
    closeCreateLibraryModal(refresh) {
      this.showCreateLibraryModal = false;
      if (refresh) {
        this.refresh();
      }
    },
    openRenameLibraryModal(library) {
      this.selectedLibrary = library;
      this.showRenameLibraryModal = true;
    },
    closeRenameLibraryModal(refresh) {
      this.showRenameLibraryModal = false;
      this.selectedLibrary = null;
      if (refresh) {
        this.refresh();
      }
    },
    closeActionPopover() {
      this.$root.$emit('bv::hide::popover');
    },
    async removeLibrary(library) {
      const response = await deleteLibrary(library);
      if (response) this.refresh();
    },
    async refresh() {
      await this.$apollo.queries.libraries.refetch();
    },
    goToDetailPage(library) {
      this.$router.push({
        name: 'Library',
        params: { id: library.id },
      });
    },
  },
};
</script>

<style scoped>
.ev-accounts-list p.cs-textstyle-paragraph {
  color: var(--cs-gray-05);
}
.videoinsight-history__feature {
  margin: 5px;
}
.ev-account-list__features {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 300px;
  vertical-align: middle;
}
.ev-spinner__wrapper {
  position: absolute;
  height: 100%;
  width: 100%;
}
.ev-spinner__wrapper .ev-spinner {
  position: relative;
  top: 40%;
  left: 40%;
  --cs-spinner-color: var(--cs-gray-03) !important;
}
.ev-libraries-list__buckets {
  display: flex;
  justify-content: center;
  gap: 20px;
}
.ev-accounts-list__accounts {
  display: flex;
  justify-content: center;
  gap: 20px;
}
.libraries-list.cs-card {
  margin-top: 24px !important;
}
.libraries-list table {
  margin-bottom: 0;
}
.libraries-list {
  margin-bottom: 50px !important;
}
.ev-account-list__search-bar.cs-search-bar {
  border: 1px solid var(--cs-gray-02);
}
</style>
