import Vue from 'vue';
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue';
import VueApollo from 'vue-apollo';
import VueObserveVisibility from 'vue-observe-visibility';
import VueMoment from 'vue-moment';
import moment from 'moment-timezone';
import apolloClient from '@/services/apollo';
import router from './router';

import App from './App.vue';

import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';

import 'cs-components';
import 'cs-components/dist/csuite.css';
import '@/assets/css/design-token.css';
import '@/assets/css/style.css';
import '@/assets/icons/css/styles.css';

Vue.config.productionTip = false;

Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(VueApollo);
Vue.use(VueObserveVisibility);
Vue.use(VueMoment, { moment });

const apolloProvider = new VueApollo({
  defaultClient: apolloClient,
});

Vue.directive('router-link', {
  inserted(el, binding, vnode) {
    el.addEventListener('click', () => {
      vnode.context.$router.push(binding.value);
    });
  },
});

Vue.filter('formatTag', (value) => value.split('_').join(' '));

new Vue({
  apolloProvider,
  router,
  mounted() {
    this.$el.addEventListener('click', () => {
      this.$root.$emit('bv::hide::popover');
    });
  },
  render: (h) => h(App),
}).$mount('#app');

moment.tz.setDefault(moment.tz.guess());
moment.locale('en', {
  relativeTime: {
    future: 'in %s',
    past: '%s ago',
    s: '%ds',
    ss: '%ss',
    m: 'a minute',
    mm: '%dm',
    h: 'an hour',
    hh: '%dh',
    d: 'a day',
    dd: '%dd',
    M: 'a month',
    MM: '%dM',
    y: 'a year',
    yy: '%dY',
  },
});
