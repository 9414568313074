<template>
  <app-gray-card>
    <app-list-control-bar
      v-model="val"
      placeholder="Filter Hooks"
      @refresh="refresh"
    >
      <cs-button corners="rounded" size="medium" @click="openCreateHook"
        >Create Hook</cs-button
      >
    </app-list-control-bar>
    <b-table
      :items="hooks"
      :fields="fields"
      outlined
      :filter="filteredResult"
      table-variant="light"
      tbody-tr-class="ev-dashboard__table-row cursor-pointer"
      :busy="$apollo.queries.hooks.loading"
      show-empty
    >
      <div slot="table-busy" class="ev-table-empty">
        <cs-spinner />
      </div>
      <div slot="empty" class="ev-table-empty">
        You don't have any hooks yet.
      </div>
      <div slot="emptyfiltered" class="ev-table-empty">No matching hooks.</div>
      <template v-slot:cell(action)="data">
        <i :id="`${data.item.id}`" class="cs-icons-options" tabindex="-1"></i>
        <b-popover
          :ref="`${data.item.id}-menu`"
          :target="`${data.item.id}`"
          placement="leftbottom"
          triggers="blur hover"
          custom-class="bg-dark"
        >
          <div>
            <div class="ev-service-list__menu-body">
              <div
                class="ev-service-list__menu-item"
                @click.stop="removeHook(data.item)"
              >
                Delete
              </div>
            </div>
          </div>
        </b-popover>
      </template>
    </b-table>
    <app-create-hook-modal
      v-if="showCreateHookModal"
      :show="showCreateHookModal"
      :library-id="id"
      @close="closeCreateHookModal(false)"
      @success="closeCreateHookModal(true)"
    ></app-create-hook-modal>
  </app-gray-card>
</template>

<script>
import ListHooks from '@/gql/hooks/ListHooks.gql';
import { deleteHook } from '@/services/confirm-delete';

import AppCreateHookModal from '@/components/library/CreateHookModal.vue';
import AppGrayCard from '@/components/general/GrayCard.vue';
import AppListControlBar from '@/components/general/ListControlBar.vue';

export default {
  components: {
    AppCreateHookModal,
    AppGrayCard,
    AppListControlBar,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  apollo: {
    hooks: {
      query: ListHooks,
      variables() {
        return {
          libraryId: this.id,
        };
      },
    },
  },
  data() {
    return {
      val: '',
      fields: [
        {
          key: 'name',
          label: 'Name',
          thClass: 'ev-dashboard__table-head',
        },
        {
          key: 'url',
          label: 'URL',
          thClass: 'ev-dashboard__table-head',
        },
        {
          key: 'action',
          label: '',
          thClass: 'ev-dashboard__table-head text-right',
          tdClass: 'text-right',
        },
      ],
      showCreateHookModal: false,
    };
  },
  computed: {
    filteredResult() {
      if (this.val) {
        return this.val;
      }
      return '';
    },
  },
  methods: {
    async removeHook(hook) {
      const response = await deleteHook(hook);
      if (response) this.refresh();
    },
    async openCreateHook() {
      this.showCreateHookModal = true;
    },
    closeCreateHookModal(refresh) {
      this.showCreateHookModal = false;
      if (refresh) this.refresh();
    },
    async refresh() {
      await this.$apollo.queries.hooks.refetch();
    },
  },
};
</script>

<style scoped>
.ev-library__hooks-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>
