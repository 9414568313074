<template>
  <div>
    <cs-spinner v-if="$apollo.queries.video.loading && !noRefresh" />
    <div v-if="video" class="ev-dashboard__header">
      <div>
        <div class="cs-textstyle-page-title">{{ video.name || video.id }}</div>
      </div>
      <div class="ev-dashboard__header-actions">
        <cs-button
          variant="danger"
          fill="solid"
          corners="rounded"
          @click="removeVideo"
          >Delete</cs-button
        >
      </div>
    </div>
    <div v-if="video && video.description">
      <div class="cs-textstyle-paragraph">
        {{ video.description }}
      </div>
    </div>
    <app-gray-card class="ev-video-details-card">
      <template v-if="video">
        <div class="ev-video-row">
          <div class="ev-video-item">
            <div class="cs-textstyle-detail-heading">
              {{ video.title ? 'Title' : 'ID' }}
            </div>
            <div>{{ video.title || video.id }}</div>
          </div>
          <div v-if="video.length" class="ev-video-item">
            <div class="cs-textstyle-detail-heading">Length</div>
            <div>
              {{
                video && video.length > 0 ? `${toTimeString(video.length)}` : ''
              }}
            </div>
          </div>
          <div v-if="video.category" class="ev-video-item">
            <div class="cs-textstyle-detail-heading">Category</div>
            <div>{{ video.category }}</div>
          </div>
          <div v-if="video.created_at" class="ev-video-item">
            <div class="cs-textstyle-detail-heading">Created</div>
            <div>
              {{ video.created_at | moment('DD MMM YYYY, hh:mm A') }}
            </div>
          </div>
        </div>
        <div class="ev-video-row">
          <div v-if="video.width" class="ev-video-item">
            <div class="cs-textstyle-detail-heading">Width</div>
            <div>{{ video.width }}</div>
          </div>
          <div v-if="video.height" class="ev-video-item">
            <div class="cs-textstyle-detail-heading">Height</div>
            <div>{{ video.height }}</div>
          </div>
          <div v-if="video.framerate" class="ev-video-item">
            <div class="cs-textstyle-detail-heading">Framerate</div>
            <div>{{ Math.round(video.framerate * 100) / 100 }}</div>
          </div>
          <div v-if="video.status" class="ev-video-item">
            <div class="cs-textstyle-detail-heading">Status</div>
            <cs-tag :class="video.status" class="status">{{
              video.status.split('_').join(' ')
            }}</cs-tag>
          </div>
        </div>
        <div v-if="video.tags && video.tags.length > 0">
          <div class="cs-textstyle-paragraph-small-bold mb-2">Tags</div>
          <div class="d-flex flex-row gap-1 flex-wrap">
            <cs-tag
              v-for="(item, ind) in video.tags"
              :key="ind"
              class="tag-class cs-textstyle-tags"
            >
              {{ item }}
            </cs-tag>
          </div>
        </div>
      </template>
    </app-gray-card>
    <div
      v-if="!$apollo.queries.video.loading && thumbnail"
      class="ev-video-bottom-row"
    >
      <app-gray-card class="ev-video-player-card">
        <div class="ev-video-thumbnail">
          <img :src="thumbnail" class="ev-video-thumbnail-image" />
        </div>
      </app-gray-card>
    </div>
  </div>
</template>

<script>
import AppGrayCard from '@/components/general/GrayCard.vue';
import GetVideo from '@/gql/videos/GetVideo.gql';
import { toTimeString } from '@/services/utils';

import { deleteVideo } from '@/services/confirm-delete';
const validThumbnailStatues = ['IMPORT_COMPLETE', 'UPLOAD_COMPLETE'];

export default {
  components: {
    AppGrayCard,
  },
  props: {
    videoId: {
      type: String,
      required: true,
    },
  },
  apollo: {
    video: {
      query: GetVideo,
      variables() {
        return {
          videoId: this.videoId,
        };
      },
    },
  },
  data() {
    return {
      noRefresh: false,
      toTimeString,
    };
  },
  computed: {
    thumbnail() {
      const {
        library_id: libraryId,
        id,
        thumbnail_file_name: thumbnailFileName,
        status,
      } = this.video;
      return validThumbnailStatues.includes(status)
        ? `https://d1k5ygkuw0d0r6.cloudfront.net/${libraryId}/${id}/${thumbnailFileName}`
        : '';
    },
  },
  mounted() {
    setInterval(() => {
      if (
        this.video &&
        this.video.status != 'IMPORT_COMPLETE' &&
        this.video.status != 'UPLOAD_COMPLETE' &&
        !this.$apollo.queries.video.loading
      ) {
        this.refresh();
      }
    }, 3000);
  },
  methods: {
    refresh() {
      this.noRefresh = true;
      this.$apollo.queries.video.refetch();
    },
    async removeVideo() {
      const response = await deleteVideo(this.video);
      if (response) {
        this.$router.push({
          name: 'Library',
          params: { id: this.video.library_id },
        });
      }
    },
  },
};
</script>

<style scoped>
.ev-video-row {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  margin-bottom: 20px;
}
.ev-video-item {
  display: flex;
  flex-direction: column;
}
.status {
  align-self: start;
}
.ev-video-thumbnail {
  display: flex;
  justify-content: center;
  align-content: center;
}
.ev-video-player-card {
  margin-top: 30px !important;
}
.ev-video-thumbnail-image {
  width: 100%;
}
.ev-video-bottom-row {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.ev-video-details-card {
  margin-top: 20px !important;
}
</style>
