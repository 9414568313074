// Files/Objects

// import RemoveConversationParticipant from '@/gql/chat/RemoveConversationParticipant.gql';
// import LeaveConversation from '@/gql/chat/LeaveConversation.gql';
// import RemoveGroupMember from '@/gql/group/RemoveGroupMember.gql';
import DeleteKey from '@/gql/keys/DeleteKey.gql';
import DeleteLibrary from '@/gql/libraries/DeleteLibrary.gql';
import DeleteHook from '@/gql/hooks/DeleteHook.gql';
import DeleteVideo from '@/gql/videos/DeleteVideo.gql';

import $bus from '@/services/bus';
import apolloClient from '@/services/apollo';
import $dialog from '@/services/dialog';

const _sendAndAwait = (params) => {
  return new Promise((resolve) => {
    const responseId = Math.floor(Math.random() * 1000000000).toString();
    $bus.$once(responseId, (response) => {
      resolve(response);
    });
    $bus.$emit('destructive-action-modal', {
      responseId,
      params,
    });
  });
};

export const removeConversationParticipant = (
  conversation,
  participant,
  name
) => {
  const params = {
    header: 'Remove Participant',
    title: `Are you sure you want to remove ${name} from the conversation?`,
    inputLabel: 'Conversation Name',
    keyword: conversation,
    deleteLabel: 'Remove',
    mutation: {
      // mutation: RemoveConversationParticipant,
      variables: { conversation_id: conversation, person_id: participant },
    },
  };
  return _sendAndAwait(params);
};

export const deleteKey = (key) => {
  const params = {
    header: 'Delete Key',
    title: 'Are you sure you want to delete this key?',
    message: `This change can't be reversed`,
    inputLabel: 'Key Name',
    keyword: key.name,
    deleteLabel: 'Delete',
    mutation: {
      mutation: DeleteKey,
      variables: {
        id: key.id,
      },
    },
  };
  return _sendAndAwait(params);
};

export const deleteLibrary = (library) => {
  const params = {
    header: 'Delete Library',
    title: 'Are you sure you want to delete this library?',
    message: `This change can't be reversed`,
    inputLabel: 'Library Name',
    keyword: library.name,
    deleteLabel: 'Delete',
    mutation: {
      mutation: DeleteLibrary,
      variables: {
        id: library.id,
      },
    },
  };
  return _sendAndAwait(params);
};

export const deleteHook = (hook) => {
  const params = {
    header: 'Delete Hook',
    title: 'Are you sure you want to delete this hook?',
    // message: `This change can't be reversed`,
    // inputLabel: 'hook Name',
    // keyword: hook.name,
    deleteLabel: 'Delete',
    mutation: {
      mutation: DeleteHook,
      variables: {
        id: hook.id,
      },
    },
  };
  return _sendAndAwait(params);
};

export const deleteVideo = (video) => {
  const params = {
    header: 'Delete Video',
    title: 'Are you sure you want to delete this video?',
    // message: `This change can't be reversed`,
    // inputLabel: 'hook Name',
    // keyword: hook.name,
    deleteLabel: 'Delete',
    mutation: {
      mutation: DeleteVideo,
      variables: {
        id: video.id,
      },
    },
  };
  return _sendAndAwait(params);
};
