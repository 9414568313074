<template>
  <div class="ev-sidebar">
    <div class="ev-sidebar__logo-container">
      <img alt="Efficient Video logo" :src="logo" class="ev-sidebar__logo" />
    </div>
    <!-- Services -->
    <ul>
      <li v-for="(link, index) in navLinks" :key="index">
        <router-link
          :to="serviceRoute(link)"
          :class="isActiveRoute(link) ? 'ev-sidebar__active-items' : ''"
          class="ev-sidebar__none-active-items"
        >
          <span class="ev-sidebar__items">
            <i v-if="link.icon" :class="link.icon" />
            <img
              v-else-if="link.svg"
              class="ev-sidebar__item-icon-svg"
              :src="link.svg"
              :alt="`${link.text} icon`"
            />
            {{ link.text }}
          </span>
        </router-link>
      </li>
    </ul>
  </div>
</template>

<script>
const logo = require('@/assets/images/EV-logo.svg');

export default {
  props: {},
  data() {
    return {
      logo,
      navLinks: [
        {
          text: 'Libraries',
          path: 'libraries',
          name: 'Libraries',
          icon: 'cs-icons-remote-filled',
        },
        {
          text: 'Users',
          path: 'users',
          name: 'Users',
          icon: 'cs-icons-group-filled',
        },
        {
          text: 'Keys',
          path: 'keys',
          name: 'Keys',
          icon: 'cs-icons-lock-filled',
        },
      ],
    };
  },
  methods: {
    isActiveRoute(link) {
      if (!this.$route.name) return false;
      return this.$route.name.includes(link.name);
    },
    serviceRoute(link) {
      const path = link.extra ? `${link.path}/${link.extra}` : link.path;
      return `/${path}`;
    },
  },
};
</script>

<style scoped>
.ev-sidebar {
  height: 100vh;
  display: flex;
  flex-direction: column;
  background: #040521;
  position: relative;
}
.ev-sidebar__logo-container {
  height: 56px;
  border-bottom: 1px solid var(--cs-gray-05);
  padding-left: 20px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.ev-sidebar__logo {
  width: 100%;
  padding-right: 16px;
}

ul {
  display: flex;
  align-items: center;
  padding-inline-start: 0;
  flex-direction: column;
  margin: 0px;
}
a {
  text-decoration: none;
  display: flex;
  align-items: center;
  color: white;
}
a:hover {
  color: var(--cs-gray-00);
}
i,
.ev-sidebar__item-icon-svg {
  margin-right: 10px;
  font-size: 22px;
}
.ev-sidebar__item-icon-svg {
  width: 20px;
  margin-top: -8px;
}
li {
  list-style-type: none;
  width: 100%;
}
.ev-sidebar__active-items {
  background-color: var(--cs-primary-base) !important;
  color: var(--cs-gray-00) !important;
  border-color: inherit !important;
}
.ev-sidebar__sub-active-items {
  background-color: var(--cs-primary-dark) !important;
  color: var(--cs-gray-00) !important;
  border-color: inherit !important;
}
.ev-sidebar__items {
  padding: 10px 20px;
  font-weight: 400;
  font-size: 14px;
}
.ev-sidebar__sub-items {
  padding: 10px 0px 10px 40px;
  font-weight: 400;
  margin-left: 15px;
  font-size: 12px;
}
.ev-sidebar__none-active-items {
  background: transparent;
}
.ev-sidebar__bottom-active-items {
  background: var(--cs-primary-base);
}
</style>
