<template>
  <app-gray-card>
    <app-list-control-bar
      v-model="searchQuery"
      placeholder="Filter Videos"
      @refresh="refresh"
      @filter="searchQuery"
      @reset="reset"
    >
      <cs-button corners="rounded" size="medium" @click="openImportVideo"
        >Import</cs-button
      >
      <cs-button corners="rounded" size="medium" @click="openUploadVideo"
        >Upload</cs-button
      >
    </app-list-control-bar>
    <div v-if="videos" class="ev-video-list__wrapper">
      <app-library-video-item
        v-for="video of filteredVideos"
        :key="video.id"
        :video="video"
      />
    </div>
    <app-spinner v-else />
    <cs-empty-state v-if="showEmptyState">
      <div slot="title">No Videos</div>
    </cs-empty-state>
    <app-infinite-simple
      v-if="videos"
      :query="$apollo.queries.videos"
      sort-key="created_at"
      :keep-going="loadMore"
      @load-finish="loadOver"
    ></app-infinite-simple>
    <app-import-video-modal
      v-if="showImportVideoModal"
      :show="showImportVideoModal"
      :library-id="id"
      @close="closeImportVideoModal(false)"
      @success="closeImportVideoModal(true)"
    ></app-import-video-modal>
    <app-upload-video-modal
      v-if="showUploadVideoModal"
      :show="showUploadVideoModal"
      :library-id="id"
      @close="closeUploadVideoModal"
      @success="redirectToVideo"
    ></app-upload-video-modal>
  </app-gray-card>
</template>
<script>
import ListVideos from '@/gql/videos/ListVideos.gql';

import AppImportVideoModal from '@/components/library/ImportVideoModal.vue';
import AppGrayCard from '@/components/general/GrayCard.vue';
import AppListControlBar from '@/components/general/ListControlBar.vue';
import AppLibraryVideoItem from '@/components/library/LibraryVideoItem.vue';
import AppInfiniteSimple from '@/components/general/InfiniteSimple.vue';
import AppSpinner from '@/components/general/Spinner.vue';
import AppUploadVideoModal from '@/components/library/UploadVideoModal.vue';

export default {
  components: {
    AppImportVideoModal,
    AppGrayCard,
    AppListControlBar,
    AppLibraryVideoItem,
    AppInfiniteSimple,
    AppSpinner,
    AppUploadVideoModal,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  apollo: {
    videos: {
      query: ListVideos,
      variables() {
        return {
          libraryId: this.id,
        };
      },
    },
  },
  data() {
    return {
      searchQuery: '',
      loadMore: true,
      showImportVideoModal: false,
      showUploadVideoModal: false,
    };
  },
  computed: {
    filteredVideos() {
      if (!this.videos) return [];
      const videos = this.videos;
      const query = this.searchQuery.trim().toLowerCase();
      if (!query) return videos;

      const filteredVideos = videos.filter((video) => {
        return video.title?.toLowerCase().indexOf(query) > -1;
      });
      return filteredVideos;
    },
    loading() {
      return this.$apollo.queries.videos.loading;
    },
    showEmptyState() {
      return (
        this.filteredVideos && this.filteredVideos.length === 0 && !this.loading
      );
    },
  },
  methods: {
    async refresh() {
      this.videos = null;
      this.loadMore = true;
      this.$apollo.queries.videos.refetch();
    },
    loadOver() {
      this.loadMore = false;
    },
    reset() {
      this.loadMore = true;
      this.$apollo.queries.videos.refetch();
    },
    openImportVideo() {
      this.showImportVideoModal = true;
    },
    closeImportVideoModal(refresh) {
      this.showImportVideoModal = false;
      if (refresh) this.refresh();
    },
    openUploadVideo() {
      this.showUploadVideoModal = true;
    },
    closeUploadVideoModal() {
      this.showUploadVideoModal = false;
    },
    redirectToVideo(vid) {
      this.$router.push({
        name: 'VideoDetail',
        params: { id: this.id, videoId: vid },
      });
    },
  },
};
</script>
<style scoped>
.ev-video-list__wrapper {
  border-radius: 5px;
  overflow: hidden;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 12px;
}
</style>
