<template>
  <div class="ev-accounts-list">
    <div class="ev-dashboard__header">
      <div>
        <div class="cs-textstyle-page-title">Keys</div>
      </div>
      <div class="ev-accounts-list__accounts">
        <cs-button corners="rounded" @click="openCreateKeyModal">
          Create Key
        </cs-button>
      </div>
    </div>
    <app-gray-card class="keys-list">
      <app-list-control-bar
        @refresh="refresh"
        v-model="val"
        placeholder="Filter Keys"
      />
      <div>
        <b-table
          :items="keys"
          :fields="fields"
          outlined
          :filter="filteredResult"
          table-variant="light"
          tbody-tr-class="ev-dashboard__table-row"
          :busy="$apollo.queries.keys.loading"
          show-empty
        >
          <div slot="table-busy" class="ev-table-empty">
            <cs-spinner />
          </div>
          <div slot="empty" class="ev-table-empty">
            You don't have any keys yet.
          </div>
          <div slot="emptyfiltered" class="ev-table-empty">
            No matching keys.
          </div>
          <template v-slot:cell(created_at)="data">
            <app-display-date-time
              :value="data.item.created_at"
              utc
              display-value="DD MMM YYYY, hh:mm A (UTC)"
            />
          </template>
          <template v-slot:cell(used_at)="data">
            <app-display-date-time
              v-if="data.item.used_at"
              :value="data.item.used_at"
            />
            <span v-else>Never</span>
          </template>
          <template v-slot:cell(action)="data">
            <i
              :id="`${data.item.id}`"
              class="cs-icons-options"
              tabindex="-1"
            ></i>
            <b-popover
              :ref="`${data.item.id}-menu`"
              :target="`${data.item.id}`"
              placement="leftbottom"
              triggers="blur hover"
              custom-class="bg-dark"
            >
              <div>
                <div class="ev-service-list__menu-body">
                  <div
                    class="ev-service-list__menu-item"
                    @click.stop="openRenameKeyModal(data.item)"
                  >
                    Rename
                  </div>
                  <div
                    class="ev-service-list__menu-item"
                    @click.stop="removeKey(data.item)"
                  >
                    Delete
                  </div>
                </div>
              </div>
            </b-popover>
          </template>
        </b-table>
      </div>
    </app-gray-card>

    <app-create-key-modal
      :show="showCreateKeyModal"
      @close="closeCreateKeyModal(false)"
      @success="closeCreateKeyModal(true)"
    />
    <app-rename-key-modal
      v-if="selectedKey"
      :selected-key="selectedKey"
      :show="showRenameKeyModal"
      @close="closeRenameKeyModal(false)"
      @success="closeRenameKeyModal(true)"
    />
  </div>
</template>

<script>
import ListKeys from '@/gql/keys/ListKeys.gql';

import AppCreateKeyModal from '@/components/keys/CreateKeyModal.vue';
import AppRenameKeyModal from '@/components/keys/RenameKeyModal.vue';
import AppListControlBar from '@/components/general/ListControlBar.vue';
import AppGrayCard from '@/components/general/GrayCard.vue';
import AppDisplayDateTime from '@/components/general/DisplayDateTime.vue';

import { deleteKey } from '@/services/confirm-delete';

export default {
  components: {
    AppCreateKeyModal,
    AppRenameKeyModal,
    AppListControlBar,
    AppGrayCard,
    AppDisplayDateTime,
  },
  apollo: {
    keys: {
      query: ListKeys,
    },
  },
  data() {
    return {
      val: '',
      fields: [
        {
          key: 'name',
          label: 'Name',
          thClass: 'ev-dashboard__table-head',
        },
        {
          key: 'created_at',
          label: 'Created Date',
          thClass: 'ev-dashboard__table-head',
        },
        {
          key: 'action',
          label: '',
          thClass: 'ev-dashboard__table-head text-right',
          tdClass: 'text-right',
        },
      ],
      showCreateKeyModal: false,
      showRenameKeyModal: false,
      selectedKey: null,
    };
  },
  computed: {
    filteredResult() {
      if (this.val) {
        return this.val;
      }
      return '';
    },
  },
  beforeMount() {
    if (this.$apollo.queries.keys) {
      this.refresh();
    }
  },
  methods: {
    openCreateKeyModal() {
      this.showCreateKeyModal = true;
    },
    closeCreateKeyModal(refresh) {
      this.showCreateKeyModal = false;
      if (refresh) {
        this.refresh();
      }
    },
    openRenameKeyModal(key) {
      this.selectedKey = key;
      this.showRenameKeyModal = true;
    },
    closeRenameKeyModal(refresh) {
      this.showRenameKeyModal = false;
      this.selectedKey = null;
      if (refresh) {
        this.refresh();
      }
    },
    closeActionPopover() {
      this.$root.$emit('bv::hide::popover');
    },
    async removeKey(key) {
      const response = await deleteKey(key);
      if (response) this.refresh();
    },
    async refresh() {
      await this.$apollo.queries.keys.refetch();
    },
  },
};
</script>

<style scoped>
.ev-accounts-list p.cs-textstyle-paragraph {
  color: var(--cs-gray-05);
}
.videoinsight-history__feature {
  margin: 5px;
}
.ev-account-list__features {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 300px;
  vertical-align: middle;
}
.ev-spinner__wrapper {
  position: absolute;
  height: 100%;
  width: 100%;
}
.ev-spinner__wrapper .ev-spinner {
  position: relative;
  top: 40%;
  left: 40%;
  --cs-spinner-color: var(--cs-gray-03) !important;
}
.ev-keys-list__buckets {
  display: flex;
  justify-content: center;
  gap: 20px;
}
.ev-accounts-list__accounts {
  display: flex;
  justify-content: center;
  gap: 20px;
}
.keys-list.cs-card {
  margin-top: 24px !important;
}
.keys-list table {
  margin-bottom: 0;
}
.ev-account-list__search-bar.cs-search-bar {
  border: 1px solid var(--cs-gray-02);
}
</style>
