export const getLastNoOfElements = (array, noOfElements) =>
  array.slice(1).slice(-noOfElements);

export const randomColor = () => {
  const code = Math.random().toString(16).slice(-6);
  return `#${code}`;
};

export const publicKey = `-----BEGIN PUBLIC KEY-----
MIGeMA0GCSqGSIb3DQEBAQUAA4GMADCBiAKBgHT95PgdnS/24t6rH0juzG7caFa1
w87CgBuuNfg6QFl2ZzpNx+wFUwCuNWWmbZNyhpsIAY2vNI+ytNvywqAitHDI1GXS
gnJttJnV+ANvl/4CcEskugA/w8b1dbbMrgfGxtuXUDZb2eICsWZ1Fy8V+BVhiOt0
9n/drddlOzHZG599AgMBAAE=
-----END PUBLIC KEY-----`;

export const signOptions = {
  algorithm: 'RS256',
};

// Allows for await timeout
export const promiseTimeout = (duration) => {
  duration = duration || 0;
  return new Promise((resolve) => {
    setTimeout(resolve, duration);
  });
};

export const convertSecToMinSec = (milliseconds) => {
  const seconds = (milliseconds / 1000) % 60;
  const minutes = Math.floor(milliseconds / 1000 / 60);
  return `${minutes}m ${parseFloat(seconds).toFixed(2)}s`;
};

export function urlBase64ToUint8Array(base64String) {
  const padding = '='.repeat((4 - (base64String.length % 4)) % 4);
  const base64 = (base64String + padding)
    .replace(/\-/g, '+')
    .replace(/_/g, '/');

  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
}

export function toTimeString(totalSeconds) {
  const totalMs = totalSeconds * 1000;
  const result = new Date(totalMs).toISOString().slice(11, 19);

  return result;
}
