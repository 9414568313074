<template>
  <div>
    <cs-modal :show="show" class="ev-import-video-modal" @close="onClose">
      <div slot="header">Import Video</div>
      <div slot="body">
        <cs-form class="ev-import-video-modal__vertical-form">
          <cs-input
            v-model="url"
            required
            label="Enter the URL"
            placeholder="https://test.com/video.mp4"
          />
        </cs-form>
        <cs-alert
          v-if="errorTitle"
          :title="errorTitle"
          :content="errorDescription"
          variant="danger"
        />
      </div>
      <cs-button
        slot="main"
        corners="rounded"
        variant="primary"
        @click="onImport"
        fill="solid"
        :disabled="!validForm || loading"
        >Import</cs-button
      >
      <div v-if="loading" class="ev-import-video-modal__loading" slot="body">
        <div class="ev-import-video-modal__loading-background"></div>
        <cs-spinner />
      </div>
    </cs-modal>
  </div>
</template>

<script>
import ImportVideo from '@/gql/videos/ImportVideo.gql';

export default {
  components: {},
  props: {
    show: {
      type: Boolean,
      required: true,
    },
    libraryId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      errorTitle: null,
      errorDescription: null,
      url: null,
    };
  },
  computed: {
    validForm() {
      return !!this.url;
    },
  },
  methods: {
    async onImport() {
      try {
        this.loading = true;
        await this.$apollo.mutate({
          mutation: ImportVideo,
          variables: {
            sourceUrl: this.url,
            libraryId: this.libraryId,
          },
        });
        this.errorTitle = null;
        this.errorDescription = null;
        this.name = null;
        this.url = null;
        this.$emit('success');
      } catch (err) {
        this.errorTitle = err.message;
        this.errorDescription =
          err.graphQLErrors[0].extensions.exception.description;
      }
      this.loading = false;
    },
    onClose() {
      if (this.loading) return;
      this.name = '';
      this.$emit('close');
    },
  },
};
</script>

<style scoped>
.ev-import-video-modal.cs-modal {
  ---cs-modal-max-height: 90%;
}
.ev-import-video-modal.cs-modal >>> .cs-modal__popup {
  border: none;
}
.ev-import-video-modal__vertical-form {
  padding-top: 16px;
  display: flex;
  flex-direction: column;
}
.ev-import-video-modal__vertical-form >>> .cs-textstyle-label-text {
  font-weight: bold;
}
.ev-import-video-modal__vertical-form >>> .cs-select__label {
  display: block;
}

.ev-import-video-modal__loading {
  display: flex;
  align-items: center;
  justify-content: center;
}
.ev-import-video-modal__loading,
.ev-import-video-modal__loading-background {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.ev-import-video-modal__loading-background {
  background-color: var(--cs-gray-06);
  opacity: 0.2;
}
</style>
