<template>
  <div class="ev-users">
    <div class="ev-dashboard__header">
      <div>
        <div class="cs-textstyle-page-title">Users</div>
      </div>
    </div>
    <app-gray-card class="users-list">
      <app-list-control-bar
        v-model="val"
        placeholder="Filter Users"
        @refresh="refresh"
      />
      <div>
        <b-table
          :items="users"
          :fields="fields"
          outlined
          :filter="filteredResult"
          table-variant="light"
          tbody-tr-class="ev-dashboard__table-row cursor-pointer"
          :busy="$apollo.queries.users.loading"
          show-empty
          @row-clicked="goToDetailPage"
        >
          <div slot="table-busy" class="ev-table-empty">
            <cs-spinner />
          </div>
          <div slot="empty" class="ev-table-empty">
            You don't have any users yet.
          </div>
          <div slot="emptyfiltered" class="ev-table-empty">
            No matching users.
          </div>
        </b-table>
      </div>
    </app-gray-card>
  </div>
</template>
<script>
import ListUsers from '@/gql/users/ListUsers.gql';

import AppListControlBar from '@/components/general/ListControlBar.vue';
import AppGrayCard from '@/components/general/GrayCard.vue';

export default {
  components: {
    AppListControlBar,
    AppGrayCard,
  },
  apollo: {
    users: ListUsers,
  },
  data() {
    return {
      val: '',
      fields: [
        {
          key: 'name',
          label: 'Name',
          thClass: 'ev-dashboard__table-head',
        },
        {
          key: 'email',
          label: 'Email',
          thClass: 'ev-dashboard__table-head',
        },
      ],
    };
  },
  computed: {
    filteredResult() {
      if (this.val) {
        return this.val;
      }
      return '';
    },
  },
  beforeMount() {
    if (this.$apollo.queries.users) {
      this.refresh();
    }
  },
  methods: {
    async refresh() {
      await this.$apollo.queries.users.refetch();
    },
  },
};
</script>

<style scoped>
.ev-users p.cs-textstyle-paragraph {
  color: var(--cs-gray-05);
}
.videoinsight-history__feature {
  margin: 5px;
}
.ev-account-list__features {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 300px;
  vertical-align: middle;
}
.ev-spinner__wrapper {
  position: absolute;
  height: 100%;
  width: 100%;
}
.ev-spinner__wrapper .ev-spinner {
  position: relative;
  top: 40%;
  left: 40%;
  --cs-spinner-color: var(--cs-gray-03) !important;
}
.users-list.cs-card {
  margin-top: 24px !important;
}
.users-list table {
  margin-bottom: 0;
}
.users-list {
  margin-bottom: 50px !important;
}
.ev-account-list__search-bar.cs-search-bar {
  border: 1px solid var(--cs-gray-02);
}
</style>
