<template>
  <div id="navbarAvatar" tabindex="-1" class="gt-avatar gt-avatar-container">
    <app-avatar size="small" :picture="user.photo_url" :name="user.name">
    </app-avatar>
    <span>{{ user.name }}</span>
    <img src="@/assets/images/arrow-down.svg" />
    <b-popover
      ref="profilePopover"
      target="navbarAvatar"
      triggers="click blur"
      placement="bottom"
    >
      <div>
        <div class="gt-avatar__body">
          <div class="gt-avatar__wrapper">
            <div
              class="cs-textstyle-paragraph-small-bold gt-avatar__name cursor-pointer"
              @click="navigateToUser"
            >
              {{ user.name }}
            </div>
            <!-- <div class="cs-textstyle-paragraph-small gt-avatar__email">
              {{ user.primary_email }}
            </div> -->
          </div>
          <!-- <div class="gt-avatar__item" @click="navigateToUser">
            <i class="cs-icons-profile"></i>
            <span class="cs-textstyle-paragraph-small-bold gt-option-item"
              >My Profile</span
            >
          </div> -->
          <div class="gt-avatar__item" @click="logout">
            <i class="cs-icons-logout"></i>
            <span class="cs-textstyle-paragraph-small-bold gt-option-item"
              >Log Out</span
            >
          </div>
        </div>
      </div>
    </b-popover>
  </div>
</template>

<script>
import AppAvatar from '@/components/general/Avatar.vue';

export default {
  components: {
    AppAvatar,
  },
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
  methods: {
    closeProfilePopover() {
      this.$refs.profilePopover.$emit('close');
    },
    logout() {
      this.$refs.profilePopover.$emit('close');
      localStorage.removeItem('access_token');
      this.$router.push({ name: 'Login' });
    },
    navigateToUser() {
      this.$router.push({ name: 'UserProfile', params: { id: this.user.id } });
      this.$root.$emit('bv::hide::popover');
    },
  },
};
</script>

<style scoped>
.gt-avatar-container {
  margin-left: 10px;
  margin-right: 10px;
}
.gt-avatar {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
  cursor: pointer;
}
.gt-avatar__body {
  display: flex;
  flex-direction: column;
  gap: 10px;
  /* width: 100% !important; */
}
.gt-avatar__item:hover {
  cursor: pointer;
}
.gt-avatar__wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-wrap: wrap;
  text-align: center;
  border-bottom: 1px solid var(--cs-gray-02);
  padding-bottom: 5px;
}
.gt-avatar__email {
  color: var(--cs-gray-04);
}
.gt-avatar i.cs-icons-play-filled {
  transform: rotate(90deg);
}
.gt-option-item {
  margin-left: 5px;
  font-size: 12px;
}
</style>
