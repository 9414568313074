<template>
  <div>
    <!-- <div class="cs-textstyle-section-heading mb-3">Details</div> -->
    <app-gray-card>
      <div class="ev-lib">
        <div class="ev-lib__individual">
          <div class="cs-textstyle-detail-heading">Library Name</div>
          <div class="cs-textstyle-paragraph">{{ lib.name }}</div>
        </div>
        <div class="ev-lib__individual">
          <div class="cs-textstyle-detail-heading">Total Video Count</div>
          <div class="cs-textstyle-paragraph">{{ lib.total_video_count }}</div>
        </div>
        <div class="ev-lib__individual">
          <div class="cs-textstyle-detail-heading">Total Video Minutes</div>
          <div class="cs-textstyle-paragraph">
            {{ lib.total_video_minutes }}
          </div>
        </div>
      </div>
    </app-gray-card>
  </div>
</template>

<script>
import AppGrayCard from '@/components/general/GrayCard.vue';
export default {
  components: {
    AppGrayCard,
  },
  props: {
    lib: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style scoped>
.ev-lib {
  display: flex;
  gap: 100px;
}
.ev-lib__individual {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
</style>
