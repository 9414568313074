<template>
  <div class="gt-infinite-loader">
    <app-infinite-loader
      :paused="query.loading"
      :finished="!mayHaveMore"
      @more="loadMore()"
    />
  </div>
</template>

<script>
import AppInfiniteLoader from '@/components/general/InfiniteLoader.vue';

export default {
  components: {
    AppInfiniteLoader,
  },
  props: {
    query: {
      type: Object,
      required: true,
    },
    sortKey: {
      type: String,
      required: true,
    },
    keepGoing: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      mayHaveMore: true,
    };
  },
  watch: {
    keepGoing: {
      handler(newVal) {
        if (newVal) {
          this.mayHaveMore = true;
          this.loadMore();
        }
      },
    },
  },
  methods: {
    loadMore() {
      if (this.mayHaveMore) {
        this.mayHaveMore = false;
        const items = this.query.vm[this.query.key];
        if (items.length === 0) {
          this.mayHaveMore = false;
          return;
        }
        const vars = {
          before: items[items.length - 1][this.sortKey],
        };
        this.query.fetchMore({
          variables: vars,
          updateQuery: (previousResult, { fetchMoreResult }) => {
            setTimeout(() => {
              this.mayHaveMore = !!fetchMoreResult[this.query.key].length;
            }, 1000);
            const output = {};
            output[this.query.key] = [
              ...previousResult[this.query.key],
              ...fetchMoreResult[this.query.key],
            ];
            return output;
          },
        });
        this.$emit('load-finish');
      }
    },
  },
};
</script>

<style scoped>
.gt-infinite-loader {
  min-height: 100px;
}
</style>
