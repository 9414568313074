<template>
  <div class="gt-topbar">
    <div class="gt-topbar__right">
      <app-user-avatar v-if="user" :user="user" />
    </div>
  </div>
</template>

<script>
// import $auth from '@/services/auth';
import GetUser from '@/gql/users/GetUser.gql';
import AppUserAvatar from '@/components/topbar/UserAvatar.vue';

export default {
  components: {
    AppUserAvatar,
  },
  props: {
    lastActiveProject: {
      type: Object,
      required: false,
      default: () => {},
    },
  },
  apollo: {
    user: GetUser,
  },
  data() {
    return {};
  },
};
</script>

<style scoped>
.gt-topbar {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  background-color: var(--cs-gray-00);
  border-bottom: 1px solid var(--cs-gray-02);
}
.gt-topbar__right {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 0px;
  justify-content: center;
  padding-right: 20px;
  font-weight: 700;
}
.gt-topbar__right i {
  font-size: 24px;
  padding: 5px 10px 0px 5px;
  color: var(--cs-gray-04);
}
</style>
