<template>
  <div>
    <div v-if="library">
      <span v-router-link="{
        name: 'Library',
        params: {
          libraryId: this.id,
        },
      }">{{ library.name }}</span>
    </div>
    <h1>Hooks</h1>
    <cs-spinner v-if="$apollo.queries.hooks.loading" />
    <div v-else>
      <pre
        v-for="hook in hooks"
        :key="hook.id"
      >
        {{ hook }}
      </pre>
    </div>
  </div>
</template>
<script>
import GetLibrary from '@/gql/libraries/GetLibrary.gql';
import ListHooks from '@/gql/hooks/ListHooks.gql';

export default {
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  apollo: {
    library: {
      query: GetLibrary,
      variables() {
        return {
          id: this.id,
        };
      },
    },
    hooks: {
      query: ListHooks,
      variables() {
        return {
          id: this.id,
        };
      },
    },
  },
};
</script>
