import { ApolloClient, InMemoryCache, HttpLink } from '@apollo/client/core';
import { split } from 'apollo-link';
import { getMainDefinition } from 'apollo-utilities';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { createClient } from 'graphql-ws';

const getHeaders = () => ({
  Authorization: localStorage.access_token
    ? `Bearer ${localStorage.access_token}`
    : '',
});

const httpLink = new HttpLink({
  uri: process.env.VUE_APP_GRAPH_ENDPOINT,
  headers: getHeaders(),
});

const wsLink = new GraphQLWsLink(
  createClient({
    url: process.env.VUE_APP_GRAPHQL_WSS,
    reconnect: true,
    connectionParams: {
      Authorization: localStorage.access_token
        ? `Bearer ${localStorage.access_token}`
        : '',
    },
  })
);

const link = split(
  // split based on operation type
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    );
  },
  wsLink,
  httpLink
);

export default new ApolloClient({
  link,
  cache: new InMemoryCache({
    typePolicies: {
      // File: {
      //   keyFields: ['key', 'created_at'],
      // },
      // Conversation: {
      //   fields: {
      //     participants: {
      //       merge(existing, incoming) {
      //         return incoming;
      //       },
      //     },
      //     messages: {
      //       merge(existing, incoming) {
      //         return incoming;
      //       },
      //     },
      //   },
      // },
      // Query: {
      //   fields: {
      //     list_messages: {
      //       merge(existing, incoming) {
      //         return incoming;
      //       },
      //     },
      //   },
      // },
      // Message: {
      //   fields: {
      //     reactions: {
      //       merge(existing, incoming) {
      //         return incoming;
      //       },
      //     },
      //   },
      // },
    },
  }),
});
