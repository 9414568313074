<template>
  <b-row class="ev-login">
    <b-col cols="4">
      <div class="ev-login__left">
        <div class="ev-login__logo">
          <img :src="logo" />
          <!-- <h2 class="cs-textstyle-page-title mt-3">Do more with your data</h2> -->
        </div>
        <img :src="illustration" class="ev-login__illustration" />
      </div>
    </b-col>
    <b-col cols="8" class="ev-login__right">
      <div class="ev-login__right--body">
        <cs-card class="ev-login__right--card">
          <div slot="header" class="ev-login__right--title">
            <div class="cs-textstyle-section-heading">Welcome!</div>
            <div class="cs-textstyle-paragraph ev-login__info">
              Login to Efficient Video by Efficient Cloud
            </div>
          </div>
          <div slot="body" class="ev-login__right--buttons">
            <form
              slot="body"
              class="ev-login__right--form"
              @submit.prevent="loginViaEmail"
            >
              <cs-input
                v-model="email"
                label="Email address"
                placeholder="Enter email address"
                :required="true"
              />
              <cs-input
                v-model="password"
                type="password"
                label="Password"
                placeholder="Enter Password"
                :required="true"
              />

              <div v-if="loading" class="ev-spinner">
                <cs-spinner></cs-spinner>
              </div>
              <cs-button
                class="login-button"
                corners="rounded"
                block
                :disabled="!validated"
                @click="loginViaEmail"
                >Login</cs-button
              >
            </form>
          </div>
        </cs-card>
        <!-- <div class="mt-4 ev-login__aggrement">
          By signing up you agree to the
          <a target="_blank" href="/legal/terms.html">Terms of Service</a>
          <br />
          and <a target="_blank" href="/legal/privacy.html">Privacy Policy</a>
        </div> -->
      </div>
      <div class="ev-login__footer">
        <div class="mx-2">
          <a href="mailto:hello@efficientcloud.com">Contact Us</a>
        </div>
        <div class="mx-2">
          <a target="_blank" href="/legal/terms.html">Terms of Service</a>
        </div>
        <div class="mx-2">
          <a target="_blank" href="/legal/privacy.html">Privacy Policy</a>
        </div>
      </div>
    </b-col>
  </b-row>
</template>

<script>
import Login from '@/gql/users/Login.gql';

const logo = require('@/assets/images/EV-logo.svg');
const illustration = require('@/assets/images/Illustration.svg');

export default {
  data() {
    return {
      logo,
      illustration,
      email: '',
      password: '',
      loading: false,
    };
  },
  computed: {
    validated() {
      return this.email && this.password;
    },
  },
  mounted() {
    if (this.$route.hash) {
      const token = this.$route.hash.split('#access_token=')[1];
      if (token) {
        localStorage.access_token = token;
        this.$router.push('/');
        window.location.reload();
      }
    }
  },
  methods: {
    async loginViaEmail(e) {
      e.preventDefault();
      try {
        const {
          data: { login: accessToken },
        } = await this.$apollo.mutate({
          mutation: Login,
          variables: {
            email: this.email,
            password: this.password,
          },
        });
        localStorage.access_token = accessToken;
        window.location.href = '/';
      } catch (err) {
        alert(err.message);
      }
    },
  },
};
</script>

<style scoped>
.ev-login {
  background-color: var(--cs-default-lightest);
  height: 100vh;
}
.ev-login__left {
  display: flex;
  flex-direction: column;
  background: var(--cs-gray-07);
  height: 100vh;
  justify-content: space-between;
  text-align: center;
  position: relative;
}
.ev-login__logo {
  margin-top: 30%;
}
.ev-login__logo img {
  width: 70%;
  max-width: 300px;
}
.ev-login__logo h2 {
  color: var(--cs-white);
}
.ev-login__illustration {
  position: absolute;
  bottom: 5%;
  left: 50%;
  transform: translate(-50%, 0%);
}

.ev-login__illustraion {
  width: 100%;
  height: 100%;
}

.ev-login__right--card {
  width: 450px;
  padding: 10px;
}
.ev-login__info {
  color: var(--cs-gray-05);
  margin-top: 5px;
}
.ev-login__header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: var(--cs-accent-dark);
  height: 150px;
}
.ev-login__right--title {
  padding: 10px 20px;
}
.ev-login__right {
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: space-between;
  position: relative;
}
.ev-login__right--form {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
}
.ev-login__right--form .cs-input {
  width: 100%;
}

.ev-login__right--body {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
}
.ev-login__right--body >>> .cs-card {
  border: 1px solid var(--cs-default-lightest);
}
.ev-login__right--buttons {
  display: flex;
  flex-direction: column;
  padding: 10px 20px;
  gap: 20px;
}
.ev-login__aggrement {
  text-align: center;
}
.ev-login__aggrement a {
  color: var(--cs-primary-base);
}
.ev-login__button {
  --cs-button-padding: 13px !important;
}
.ev-login__footer {
  border-top: 1px solid var(--cs-gray-02);
  display: flex;
  padding: 30px;
  justify-self: flex-end;
  justify-content: center;
  flex-direction: row;
  color: var(--cs-gray-04);
  gap: 30px;
}
.row > * {
  padding: 0px;
}
@media (max-width: 1500px) {
  .ev-login__illustration {
    width: 90%;
    padding-left: 20px;
  }
}
.cs-textstyle-page-title {
  font-size: var(--cs-font-size-300);
}
</style>
