<template>
  <div>
    <cs-modal :show="show" class="ev-library-modal" @close="onClose">
      <div slot="header">Rename Library</div>
      <div slot="body">
        <cs-form class="ev-library-modal__vertical-form">
          <!-- Library Name -->
          <cs-input v-model="name" required label="Library Name" />
        </cs-form>
        <cs-alert
          v-if="errorTitle"
          :title="errorTitle"
          :content="errorDescription"
          variant="danger"
        />
      </div>
      <cs-button
        slot="main"
        corners="rounded"
        variant="primary"
        @click="onRename"
        fill="solid"
        :disabled="!validForm || connecting"
        >Rename</cs-button
      >
      <div v-if="connecting" class="ev-library-modal__loading" slot="body">
        <div class="ev-library-modal__loading-background"></div>
        <cs-spinner />
      </div>
    </cs-modal>
  </div>
</template>
  
  <script>
import RenameLibrary from '@/gql/libraries/RenameLibrary.gql';

export default {
  components: {},
  props: {
    show: {
      type: Boolean,
      required: true,
    },
    selectedLibrary: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      name: this.selectedLibrary.name,
      connecting: false,
      errorTitle: null,
      errorDescription: null,
    };
  },
  computed: {
    validForm() {
      return !!this.name;
    },
  },
  methods: {
    async onRename() {
      try {
        this.connecting = true;
        await this.$apollo.mutate({
          mutation: RenameLibrary,
          variables: { id: this.selectedLibrary.id, name: this.name },
        });
        this.errorTitle = null;
        this.errorDescription = null;
        this.$emit('close');
      } catch (err) {
        this.connecting = false;
        this.errorTitle = err.message;
        this.errorDescription =
          err.graphQLErrors[0].extensions.exception.description;
      }
    },
    onClose() {
      if (this.connecting) return;
      this.name = '';
      this.$emit('close');
    },
  },
};
</script>
  
  <style scoped>
.ev-library-modal.cs-modal {
  ---cs-modal-max-height: 90%;
}
.ev-library-modal.cs-modal >>> .cs-modal__popup {
  border: none;
}
.ev-library-modal__vertical-form {
  padding-top: 16px;
  display: flex;
  flex-direction: column;
}
.ev-library-modal__vertical-form >>> .cs-textstyle-label-text {
  font-weight: bold;
}
.ev-library-modal__vertical-form >>> .cs-select__label {
  display: block;
}

.ev-library-modal__loading {
  display: flex;
  align-items: center;
  justify-content: center;
}
.ev-library-modal__loading,
.ev-library-modal__loading-background {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.ev-library-modal__loading-background {
  background-color: var(--cs-gray-06);
  opacity: 0.2;
}
.ev-library-token-container {
  position: relative;
}
.cs-icons-copy {
  font-size: 30px;
  color: var(--cs-primary-base);
  cursor: pointer;
}
.ev-library-token-container .copy-success {
  position: absolute;
}

.ev-library-token-container .copy-success .cs-alert {
  width: 140px;
}
.token-heading {
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.6px;
  text-transform: uppercase;
  color: var(--cs-gray-06);
}
.token-body {
  display: flex;
  align-items: center;
  gap: 10px;
}
</style>
  