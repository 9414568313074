<template>
  <div>
    <cs-spinner v-if="$apollo.queries.library.loading" />
    <div v-else>
      <div v-if="library" class="ev-dashboard__header">
        <div>
          <div class="cs-textstyle-page-title">{{ library.name }}</div>
        </div>
        <div class="ev-dashboard__header-actions">
          <cs-button
            variant="primary"
            fill="outline"
            corners="rounded"
            @click="openRenameLibraryModal"
            >Rename</cs-button
          >
          <cs-button
            variant="danger"
            fill="solid"
            corners="rounded"
            @click="removeLibrary"
            >Delete</cs-button
          >
        </div>
      </div>
      <cs-page-tabs class="mt-3">
        <cs-page-tab label="Overview">
          <app-library-general-details
            :lib="library"
          ></app-library-general-details>
        </cs-page-tab>
        <cs-page-tab :label="`Videos (${library.total_video_count})`">
          <app-library-videos :id="id"></app-library-videos>
        </cs-page-tab>
        <cs-page-tab label="Hooks">
          <app-library-hooks :id="id"></app-library-hooks>
        </cs-page-tab>
        <cs-page-tab label="Domains">
          <app-library-domains :id="id"></app-library-domains>
        </cs-page-tab>
      </cs-page-tabs>
    </div>
    <app-rename-library-modal
      v-if="library"
      :selected-library="library"
      :show="showRenameLibraryModal"
      @close="closeRenameLibraryModal"
      @success="closeRenameLibraryModal"
    />
  </div>
</template>

<script>
import GetLibrary from '@/gql/libraries/GetLibrary.gql';
import { deleteLibrary } from '@/services/confirm-delete';

import AppRenameLibraryModal from '@/components/library/RenameLibraryModal.vue';
import AppLibraryGeneralDetails from '@/components/library/LibraryGeneralDetails.vue';
import AppLibraryVideos from '@/components/library/LibraryVideosList.vue';
import AppLibraryHooks from '@/components/library/LibraryHooksList.vue';
import AppLibraryDomains from '@/components/library/LibraryDomainsList.vue';

export default {
  components: {
    AppRenameLibraryModal,
    AppLibraryGeneralDetails,
    AppLibraryVideos,
    AppLibraryHooks,
    AppLibraryDomains,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  apollo: {
    library: {
      query: GetLibrary,
      variables() {
        return {
          id: this.id,
        };
      },
    },
  },
  data() {
    return {
      showRenameLibraryModal: false,
    };
  },
  methods: {
    openRenameLibraryModal() {
      this.showRenameLibraryModal = true;
    },
    closeRenameLibraryModal() {
      this.showRenameLibraryModal = false;
    },
    async removeLibrary() {
      const response = await deleteLibrary(this.library);
      if (response) {
        this.$router.push({
          name: 'Libraries',
        });
      }
    },
  },
};
</script>

<style lang="css" scoped></style>
